import React from 'react';
import { Helmet } from 'react-helmet';
import Tags from '../components/Tags';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import contentData from '../data/content-data.json';

const tagsPage = () => {
  const { title, lang } = headData;

  const titleText = `音楽につけられたタグの一覧ページ | ${title}`;
  const description = `こちらから興味のあるタグが付けられた音楽を探すことができます。 | ${title}`;

  // Create tag pages
  const tags = [];
  contentData.projectsData.forEach((d) => {
    d.tags.forEach((t) => {
      if (!tags.includes(t)) {
        tags.push(t);
      }
    });
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titleText}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description} />
      </Helmet>
      <Tags tags={tags} />
    </>
  );
};

export default tagsPage;
