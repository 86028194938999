import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';
import { getTagUrl } from '../../utils/tags';

function TagList({ tags }) {
  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="音楽につけられたタグ一覧" />
          <div className="project-wrapper__text" style={{ textAlign: 'center' }}>
            {tags.map((tag) => (
              <a href={`/tags/${getTagUrl(tag)}/`} key={tag}>
                <h3 className="project-wrapper__text-title">{tag}</h3>
              </a>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}

TagList.propTypes = {
  tags: PropTypes.node.isRequired,
};

export default TagList;
