import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Hero2 from './Hero/Hero2';
import TagList from './TagList/TagList';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { heroData, footerData } from '../data/data';

function Tags({ tags }) {
  const [hero, setHero] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, footer }}>
      <Hero2 />
      <TagList tags={tags} />
      <Footer />
    </PortfolioProvider>
  );
}

Tags.propTypes = {
  tags: PropTypes.node.isRequired,
};

export default Tags;
